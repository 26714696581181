:root {
  --eventv3-card-height: min(calc(100vh - 160px), 534px);
  --eventv3-card-width: min(
    calc(var(--eventv3-card-height) * (358 / 534)),
    358px
  );
}
.eventv3_description_container {
  border-radius: 20px;
  border: 1.976px solid rgba(255, 255, 255, 0.6);
  background: linear-gradient(
    328deg,
    rgba(255, 255, 255, 0.6) 1.35%,
    rgba(255, 255, 255, 0.1) 101.35%
  );
  backdrop-filter: blur(24.703088760375977px);
  padding: 16px;
  /* margin-top: 24px; */
}

.evevtv3_description {
  color: #000;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.eventv3_itinerary_container {
  margin-top: 24px;
}

.eventv3_header {
  color: #000;

  /* lato bh1 */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}

.eventv3_itn_card_container {
  border-radius: 20px;
  position: sticky;
  top: calc(var(--navbar-offset) + 84px);
  transition: top 0.3s ease-in-out;
}

.eventv3_itn_card_top_section {
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(180deg, #000 11.46%, rgba(0, 0, 0, 0) 88.99%);
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  justify-content: space-between;
}

.eventv3_itn_card_heading {
  color: #fff;
  /* text-align: center; */

  /* lato heading */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
}

.eventv3_itn_card_bottom_section {
  border-radius: 0px 0px 20px 20px;
  background: #fdf5f5;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.eventv3_itn_card_description {
  color: #000;

  /* lato bb1 */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}

.eventv3_itn_card_bottom_section_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eventv3_itn_card_bottom_section_bottom_left {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.eventv3_itinerary_container_cards {
  display: flex;
  flex-direction: column;
  gap: 96px;
  margin-top: 24px;
}

.eventv3_activities_container {
  margin-top: 68px;
}

.eventv3_activities_container_cards {
  display: flex;
  gap: 9px;
  overflow-x: scroll;
  margin-top: 24px;
}

.eventv3_activities_container_card {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.eventv3_activities_image {
  width: 172.922px;
  height: 114.622px;
  flex-shrink: 0;
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
}

.eventv3_activities_container_card_bottom {
  background: #fff;
  border-radius: 0px 0px 20px 20px;
  padding: 10px;
  /* min-height: 43px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventv3_activities_container_card_bottom_title {
  overflow: hidden;
  color: #131517;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* lato sh1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}

.eventv3_accomodation_container {
  margin-top: 52px;
}

.eventv3_accomodation_container_cards {
  margin-top: 24px;
  display: flex;
  gap: 9px;
  overflow-x: scroll;
}

.eventv3_accomodation_container_card {
  padding: 20px;
  min-width: calc(100% - 48px);
  box-sizing: border-box;
  border-radius: 20px;
  background: #fff;
}

.eventv3_creator_image {
  display: flex;
  width: 305.732px;
  height: 360px;
  justify-content: center;
  align-items: flex-end;
  gap: 10.498px;
  border-radius: 20.997px;
  margin: 24px auto 16px;
}

.eventv3_creator_description {
  color: #000;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.eventv3_footer_container {
  position: sticky;
  bottom: 0;
  z-index: 1000;
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;

  background: linear-gradient(
    4deg,
    rgba(255, 255, 255, 0.5) 4.88%,
    rgba(153, 153, 153, 0) 96.65%
  );
  backdrop-filter: blur(10px);
  max-width: 800px;
  margin: 0 auto;
}

.eventv3_footer_text {
  color: #000;

  /* lato sh1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.eventv3_footer_button {
  display: flex;
  width: 114.622px;
  height: 37.549px;
  justify-content: center;
  align-items: center;
  gap: 8.893px;
  flex-shrink: 0;
  border-radius: 11.857px;
  background: #fd4f4f;
  color: #fff;

  /* lato sh1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  cursor: pointer;
}

.book_now_event_detail_container {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
}

.book_now_event_detail_container_right_title {
  color: #000;

  /* lato heading */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
}

.book_now_modal_text_secondary {
  color: #000;

  /* lato b1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

.book_now_modal_text_primary {
  color: #000;

  /* lato b1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}

.book_now_modal_heading {
  color: #000;

  /* lato bh1 */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}

ul {
  margin: 0;
  padding-inline-start: 16px;
}

.book_now_modal_button {
  display: flex;
  padding: 13.834px 19.762px;
  justify-content: center;
  align-items: center;
  gap: 8.893px;
  align-self: stretch;
  border-radius: 11.857px;
  background: #fd4f4f;
  color: #fff;

  /* lato sh1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  width: 100%;
  cursor: pointer;
}

.book_now_modal_button:disabled {
  background: rgba(0, 0, 0, 0.6);
  cursor: not-allowed;
}

.book_now_modal_button:disabled {
  background: rgba(0, 0, 0, 0.6);
  cursor: not-allowed;
}

.price_details_header {
  color: #000;

  /* lato bbbbhh */
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
}

.eventv3_testimonial_container {
  height: 180px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 20px;
  border: 1.976px solid rgba(239, 239, 239, 0.6);
  background: linear-gradient(
    330deg,
    rgba(239, 239, 239, 0.8) 0.16%,
    rgba(239, 239, 239, 0.1) 101.39%
  );
  box-shadow: -0.988px -0.988px 3.952px 0px rgba(0, 0, 0, 0.25);
  min-width: 137.786px;
  box-sizing: border-box;
  align-self: flex-start;
  align-content: space-between;
  backdrop-filter: blur(4px);
}

.eventv3_testimonial_description {
  color: #000;

  /* lato bb1 */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eventv3_testimonial_name {
  color: #808080;

  /* lato bb1 */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}

.ticket_type_div_container {
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  background: #fdfdfd;
  margin-top: 12px;
}

.book_now_modal_text_tertiary {
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
}

.book_now_modal_button_small {
  display: flex;
  height: 27px;
  padding: 2.5px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(253, 79, 79, 0.8);
  color: #fff;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.43px;
  cursor: pointer;
}

.book_now_modal_selected_ticket_type {
  display: flex;
  height: 27px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  color: #000;
  text-align: center;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.book_now_modal_selected_ticket_type_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.book_now_modal_selected_ticket_type_right {
  display: flex;
  width: 79.313px;
  height: 27px;
  padding: 3px 12.656px 2px 11.813px;
  justify-content: center;
  align-items: center;
  gap: 12.5px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--Fills-Tertiary, rgba(120, 120, 128, 0.12));
}

.book_now_modal_selected_ticket_type_right_text {
  color: var(--Labels-Primary, #000);
  text-align: center;
  font-family: "SF Pro";
  font-size: 24px;
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  cursor: pointer;
}

.custom_question_input {
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  color: #a8a9aa;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  width: 100%;
}

.custom_question_checkbox_container_item {
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  color: #a8a9aa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.eventv3_accomodation_container_cards {
  /* ... existing styles ... */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.eventv3_accomodation_container_cards::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.dot-navigation {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 16px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d9d9d9;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  width: 10px;
  height: 10px;
  background-color: #000000;
  transform: scale(1.2);
}

.eventv3_accomodation_container_card {
  /* ... existing styles ... */
  scroll-snap-align: start;
  flex: 0 0 100%;
}

.eventv3_creator_title {
  color: #fff;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.eventv3_creator_name {
  color: #fff;

  /* lato bh1 */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}

.eventv3_container {
  margin: 0px auto;
}

@media (min-width: 600px) {
  .event_v3_container {
    display: flex;
    gap: 24px;
    margin: 36px auto 0px;
    justify-content: center;
  }
  .eventv3_description_container {
    margin-top: 0px;
  }
  .react-modal-sheet-container {
    max-width: 600px;
    left: calc(50vw - 300px) !important;
  }
  .eventv3_container_content {
    width: 370px;
    margin-top: 36px;
    position: sticky;
    top: calc(var(--navbar-offset) + 36px);
    height: calc(100vh - 140px);
    overflow-y: auto;
  }
  .eventv3_accomodation_container_card {
    min-width: 368px;
    width: 368px;
    flex: unset;
  }
  .eventv3_itn_card_container {
    min-width: 368px;
    width: 368px;
    flex: unset;
    top: 48px;
  }
  .eventv3_itinerary_container {
    margin-top: 0px;
  }
}
