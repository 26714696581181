.search_bar_container {
  display: flex;
  padding: 7px 8px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid #ebeded;
  background: #fbfcfc;
  box-sizing: border-box;
  height: 36px;
}

.search_bar_input {
  overflow: hidden;
  color: #b1b2b2;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.43px;
  flex: 1;
  border: none;
  outline: none;
  box-sizing: border-box;
  background: transparent;
}

.title {
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.all_experience_container {
  margin-top: 36px;
}

.all_experience_cards_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.empty_container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.empty_container p {
  color: #606164;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.search_results_title {
  color: rgba(0, 0, 0, 0.8);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (min-width: 600px) {
  .all_experience_cards_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}

.time_filter_container {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px;
  display: flex;
  margin-top: 24px;
  position: relative;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: 36px;
  box-sizing: border-box;
}

.time_filter_option {
  display: flex;
  gap: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 32px;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.time_filter_option_active {
  background-color: transparent;
}

.time_filter_option_title {
  color: rgb(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.093px;
  font-family: Lato;
}

.time_filter_option_title_active {
  color: #000;
}

.time_filter_option_icon path {
  fill: rgb(0, 0, 0, 0.6);
}

.time_filter_option_icon_active path {
  fill: #000;
}

.sliding_background {
  position: absolute;
  top: 2px;
  left: 2px;
  height: calc(100% - 4px);
  width: calc(50% - 2px);
  background: #fff;
  border-radius: 6px;
  transition: transform 0.3s ease-in-out;
  z-index: 0;
}
