.event_card_container {
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  aspect-ratio: 358/534;
}

.event_card_top_section {
  display: flex;
  padding: 24px;
  border-radius: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.event_card_date_container {
  border-radius: 11px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 40px;
  height: 40px;
}

.event_card_date_container_month {
  color: #ff0f0f;
  text-align: center;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 12px */
  backdrop-filter: blur(4.197531223297119px);
}

.event_card_date_container_day {
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
}

.event_card_bottom_section {
  display: flex;
  padding: 24px;
  align-items: flex-end;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(11, 11, 11, 0) 4.14%, #0b0b0b 100%);
}

.creator_img {
  width: 118px;
  height: 125px;
  flex-shrink: 0;
  border-radius: 20px;
  box-shadow: 0px 4.124px 4.124px 0px rgba(0, 0, 0, 0.25);
  object-fit: cover;
}

.text_primary {
  color: #fff;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.text_secondary {
  color: rgba(255, 255, 255, 0.7);

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.text_large {
  color: #fff;

  /* lato heading */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  margin-top: 8px;
}

@media (min-width: 600px) {
  .event_card_container {
    max-width: 358px;
    max-height: 534px;
    width: 358px;
    height: 534px;
  }
}
