.auth_container {
  margin-top: 48px;
  padding: 36px;
}

.auth_header {
  color: #282828;

  /* lato bh1 */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}

.auth_subheader {
  color: #282828;

  /* lato bh1 */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}

.auth_input_label {
  color: var(--V3-Black, #212427);

  /* lato b1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  margin-bottom: 8px;
}

.auth_input {
  outline: none;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: var(--V3-Black, #212427);
  outline: none;
  padding: 20px 15px;
  border-radius: 20px;
  border: 1.5px solid var(--Grey-5, #ddd);
  background: transparent;
  width: 100%;
  box-sizing: border-box;
}

.auth_input_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 36px;
}

.auth_input_container:focus {
  border: 1.5px solid #000;
}

.forgot_password_text {
  color: var(--Grey-1, #4b4b4b);
  text-align: right;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  text-decoration: right;
  cursor: pointer;
  text-decoration: underline;
}

.auth_button {
  display: flex;
  padding: 13.834px 19.762px;
  justify-content: center;
  align-items: center;
  gap: 8.893px;
  align-self: stretch;
  border-radius: 20px;
  background: #fd4f4f;
  cursor: pointer;
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}

.auth_or_block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 23px;
}

.auth_or_block_text {
  color: #666;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.auth_or_block_line {
  height: 1px;
  flex: 1 0 0;
  background: rgba(102, 102, 102, 0.25);
}

.insta_login_container {
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  border: 1px solid var(--V3-grey-3, #969696);
  background: #fff;
  padding: 13px 0px;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
}

.insta_login_text {
  color: #4b4b4b;

  /* lato sh1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}

.login_icon_wrapper {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 9999px;
  border: 2px solid #e2e8f0;
  background: #fff;
  cursor: pointer;
}
