.thanks-text {
  color: #000;
  font-family: Poppins;
  font-size: 39px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 143.59% */
  text-align: center;
}

.thanks-subtext {
  color: #252525;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
  margin-top: 12px;
}

.thanks-button {
  display: flex;
  width: 213.021px;
  padding: 16px 72.021px 15px 73px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: #5a8ae9;
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 79.167% */
  cursor: pointer;
  margin-top: 51px;
  align-self: center;
  margin-bottom: 20px;
}

.thanks-button:hover {
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}
