:root {
  --navbar-offset: 48px;
}

.navbar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background: #fff;
  /* box-shadow: 0px 1.99px 11.94px 0px rgba(0, 0, 0, 0.25); */
  position: sticky;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
}

.logo_text {
  color: #000;
  text-align: right;
  font-family: Niramit;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 24px */
  cursor: pointer;
}

.navbar_container {
  /* ... existing styles ... */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.navbar_hidden {
  transform: translateY(-100%);
}
