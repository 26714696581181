.create_event_container {
  min-height: 100vh;
}

.create_event_heading_1 {
  color: #636f79;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.342px; /* 188.158% */
  letter-spacing: -0.515px;
}

.create_event_heading_2 {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 49.092px;
  letter-spacing: 0.479px;
}

.create_event_heading_container {
  padding: calc(48px + 24px) 20px 12px;
  transition: all 0.3s ease;
}

.create_event_heading_2_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create_event_heading_2_container_arrow {
  border-radius: 2.357px;
  background: #dbe6ec;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.arrow_tilted {
  width: 8px;
  height: 8px;
}

.create_event_option_buttons_container {
  display: flex;
  align-items: center;
  gap: 2.2px;
  margin: 0px 20px;
  padding-bottom: 8px;
  overflow-x: auto;
}

.create_event_option_buttons_container_button_selected {
  display: flex;
  padding: 3px 10px;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  background: #fff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12),
    0px 3px 1px 0px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  flex: 1;
}

.create_event_option_buttons_container_button {
  display: flex;
  padding: 3px 10px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
}

.glance_container {
  margin-top: 27px;
}

.glance_container_title_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 152.941% */
  letter-spacing: -0.1px;
}

.glance_container_content_item_header {
  color: #ff6b5b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 80% */
  text-transform: lowercase;
}

.glance_container_content_item_header_secondary {
  color: #ff6b5b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: lowercase;
}

.glance_container_content_item {
  margin-top: 16px;
}

.glance_container_content_line {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 100px;
  background: var(--Fills-Secondary, rgba(120, 120, 128, 0.16));
  margin: 12px 0px;
}

.glance_container_content_line_item {
  width: 53px;
  height: 7px;
  background: #ff6b5b;
  border-radius: 100px;
}

.glance_container_going_text_container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.glance_container_going_text_dot {
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #ff6b5b;
}

.glance_container_going_text_primary {
  color: #ff6b5b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 123.077% */
  text-transform: capitalize;
}

.glance_container_content_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.glance_container_content_item_secondary {
  color: var(--Labels-Secondary, rgba(60, 60, 67, 0.6));
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  text-transform: lowercase;
}

.glance_container_content_item_secondary_secondary {
  color: var(--Labels-Secondary, rgba(60, 60, 67, 0.6));
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: lowercase;
}

.guest_options_buttons {
  display: flex;
  padding: 0px 10.294px;
  align-items: center;
  justify-content: center;
  gap: 5.882px;
  flex-shrink: 0;
  border-radius: 17.647px;
  background: #fdfdfd;
  height: 48px;
  cursor: pointer;
}

.guest_options_container {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 27px;
  margin-bottom: 25.26px;
  overflow-x: auto;
}

.guest_options_buttons_icon_container {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guest_options_buttons_text {
  overflow: hidden;
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.1px;
}

.guest_options_container_line {
  background: #e3e4e5;
  height: 1px;
  width: 100%;
}

.guest_list_container_header_button {
  display: flex;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 3px;
  background: #ebeced;
  cursor: pointer;
}

.guest_list_container {
  margin-top: 22px;
}

.guest_list_container_header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.guest_list_container_header_title {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.1px;
  flex: 1;
}

.guest_list_search_container {
  display: flex;
  width: 100%;
  padding: 7px 8px;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #ebeded;
  background: #fbfcfc;
  gap: 2px;
  box-sizing: border-box;
  margin-top: 8px;
}

.guest_list_search_input {
  padding: 0px;
  width: 100%;
  color: #b1b2b2;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  border: none;
  outline: none;
}

.guest_list_filter_container {
  margin-top: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guest_list_filter_button {
  height: 29px;
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 10px;
  background: #ebeced;
}

.guest_list_filter_button_text {
  color: #606164;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.43px;
}

.filtered_guest_list_filter_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
}

.guest_list_filter_item_container {
  border-radius: 24px;
  background: #fdfdfd;
  padding: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.guest_list_filter_item_container_right_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guest_list_filter_item_container_right_top_name {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.23px;
}

.guest_list_filter_item_container_right_top_time {
  display: flex;
  padding-left: 1px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.guest_list_filter_item_container_right_email {
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.23px;
}

.guest_list_filter_item_container_right {
  width: 100%;
}

.tickets_container {
  margin-top: 35px;
}

.tickets_container_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tickets_container_header_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 152.941% */
  letter-spacing: -0.1px;
}

.tickets_container_header_button {
  width: fit-content;
  flex-shrink: 0;
  border-radius: 10px;
  background: #ebeced;
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}

.tickets_container_header_button_text {
  color: #606164;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.ticket_type_container {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: #fff;
  justify-content: space-between;
}

.ticket_type_container_name {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.ticket_type_container_type {
  color: #a8a9aa;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.ticket_type_container_tag_container {
  border-radius: 9.5px;
  background: #f8efe1;
  padding: 5px 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.ticket_type_container_tag {
  color: #d69712;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */
}

.ticket_type_container_number_of_registrations {
  color: #a8a9aa;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
}

.ticket_type_container_left {
  display: flex;
  gap: 4px;
  align-items: center;
}

.registration_email_container {
  margin-top: 7px;
}

.registration_email_container_header {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.1px;
}

.registration_email_container_subheading {
  color: #737577;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
  backdrop-filter: blur(67.95704650878906px);
  margin-top: 11px;
}

.registration_email_cards {
  width: 176px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 21px;
  cursor: pointer;
}

.registration_email_cards_container {
  display: flex;
  gap: 6px;
  margin-top: 20px;
  overflow-x: auto;
}

.registration_email_cards_top {
  flex: 1;
  padding: 12px 6px 10px;
  background: linear-gradient(180deg, #fefefe 6.28%, #f4f4f5 99.96%);
  width: 100%;
  box-sizing: border-box;
  border-radius: 21px 21px 0px 0px;
}

.fat_line_1 {
  margin: 17px 0px 8px 0px;
  width: 134px;
  height: 9px;
  border-radius: 3px;
  background: #e6e7e8;
}

.fat_line_2 {
  margin-bottom: 10px;
  width: 60px;
  height: 9px;
  border-radius: 3px;
  background: #e6e7e8;
}

.registration_email_cards_title {
  overflow: hidden;
  color: #131517;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.registration_email_cards_bottom {
  background: #fdfdfe;
  padding: 12px 7px 13px 10px;
  width: 176px;
  box-sizing: border-box;
  border-radius: 0px 0px 21px 21px;
}

.registration_queries_container {
  margin-top: 24px;
}

.registration_queries_personal_container {
  margin-top: 18px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.registration_queries_personal_container_text {
  overflow: hidden;
  color: #131517;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.registration_queries_questions_container {
  border-radius: 10px;
  background: var(--backgrounds-grouped-secondary, #fff);
  padding: 0px 0px 0px 16px;
  margin-top: 18px;
}

.registration_queries_questions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.registration_queries_questions_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  padding-bottom: 11px;
  padding-top: 11px;
  width: 100%;
  box-sizing: border-box;
}

.registration_queries_questions_tag {
  color: var(--Labels-Secondary, rgba(60, 60, 67, 0.6));
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
}

.registration_queries_questions_title {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
}

.registration_queries_questions_dropdown {
  border: none;
  outline: none;
}

.registration_queries_questions_dropdown_option {
  color: var(--Labels-Secondary, rgba(60, 60, 67, 0.6));
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
}

.custom-registration_queries_questions_dropdown select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none; /* Hide the default dropdown */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background: transparent;
  background-color: white;
  position: relative;
}

.send_blast_container {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  box-sizing: border-box;
  margin-top: 36px;
}

.send_blast_input_container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
}

.send_blast_button_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px 8px 16px;
  width: 100%;
  box-sizing: border-box;
}

.send_blast_input_divider {
  background: rgba(84, 84, 86, 0.34);
  height: 0.333px;
  width: calc(100% - 57px);
  margin-left: 57px;
}

.advanced_text {
  overflow: hidden;
  color: #a8a9aa;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
  width: fit-content;
}

.send_blast_button_container_icon {
  display: flex;
  align-items: center;
  gap: 3px;
  flex: 1;
  cursor: pointer;
}

.send_blast_button {
  display: flex;
  width: 74px;
  height: 29px;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 12px;
  color: var(--Grays-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
  cursor: pointer;
}

.send_blast_button_inactive {
  background: #98999a;
}

.send_blast_button_active {
  background: #000;
}

.send_blast_container_text {
  color: #a8a9aa;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.send_blast_input {
  border: none;
  outline: none;
  background: transparent;
}

.send_blast_input::placeholder {
  color: #a8a9aa;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.sent_blast_container {
  margin-top: 28px;
}

.sent_blast_container_text {
  overflow: hidden;
  color: #9fa6a8;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
}

.sent_blast_card {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  display: flex;
  gap: 11px;
  margin-top: 7px;
}

.sent_blast_card_content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sent_blast_card_content_top_name {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.sent_blast_card_content_top_time {
  color: #a8a9aa;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.1px;
}

.sent_blast_card_content_msg {
  margin-top: 5px;
  overflow: hidden;
  color: #67686a;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
}

.sent_blast_card_content_sentTo {
  margin-top: 9px;
  overflow: hidden;
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
}

.sent_blast_container_cards {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.system_message {
  margin-top: 24px;
  padding-bottom: 28 px;
}

.system_message_container_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 133.333% */
}

.system_message_container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  background: #fdfdfd;
  margin-top: 15px;
}

.system_message_card {
  display: flex;
  gap: 12px;
  padding: 12px 16px;
}

.system_message_card_icon {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 7px;
  background: #f4f4f4;
  margin-top: 28px;
}

.system_message_card_content_title {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.system_message_card_content_description {
  overflow: hidden;
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
  margin-top: 5.5px;
}

.system_message_card_content_button {
  border-radius: 8px;
  background: #f4f4f4;
  display: flex;
  padding: 3px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  color: #646567;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.43px;
  cursor: pointer;
  margin-top: 15.5px;
}

.insight_header_container {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.insight_header_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 133.333% */
}

.insight_header_button {
  background: #ebeced;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 4px 7px;
  color: #606164;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.43px;
  gap: 2px;
}

.insight_header_subtext {
  overflow: hidden;
  color: #737577;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
}

.chart_div {
  width: 100%;
  height: 241px;
  background: #fff;
  border-radius: 11px 11px 0px 0px;
}

.chart_info_container {
  border-radius: 0px 0px 11px 11px;
  background: #f4f4f4;
  width: 100%;
  padding: 13px 18px;
  border: 2px solid #fff;
  box-sizing: border-box;
}

.chart_info_heading {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 150% */
}

.chart_info_label_container {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart_info_label_text {
  overflow: hidden;
  color: #a3a4a4;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
  letter-spacing: 0.4px;
}

.chart_info_label_value {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  margin-top: 6px;
}

.chart_info_additional_container {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart_info_additional_text {
  overflow: hidden;
  color: #646567;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  letter-spacing: 0.4px;
}

.chart_info_additional_text_2 {
  overflow: hidden;
  color: #131313;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
  letter-spacing: 0.4px;
}

.add_utm_text {
  margin-top: 8px;
  color: #a3a4a4;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 138.462% */
  letter-spacing: 0.4px;
}

.insight_header_button_2 {
  display: flex;
  border-radius: 8px;
  background: #e1e8eb;
  padding: 2.5px 11px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  color: #646567;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.43px;
  cursor: pointer;
}

.no_reff_image {
  margin: auto;
  margin-top: 75px;
}

.no_mail_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no_reff_heading_text {
  color: #646567;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.no_reff_content_text {
  overflow: hidden;
  color: #a3a5a5;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 112.5% */
}

.no_mail_button_container {
  padding: 32px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more_header_container {
  margin-top: 32px;
}

.more_button {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #333537;
  color: var(--Grays-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
  cursor: pointer;
}

.event_page_url_input_container {
  display: flex;
  margin-top: 42px;
}

.event_page_url_input_container_left {
  border-radius: 10px 0px 0px 10px;
  background: #ebeced;
  padding: 8px;
}

.event_page_url_input_container_left_text {
  color: #616265;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
}

.event_page_url_input_container_right {
  width: 100%;
  box-sizing: border-box;
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  display: flex;
  max-width: 231px;
  padding: 7px 8px 7px 8px;
  align-items: center;
  border-radius: 0px 10px 10px 0px;
  border: 1px solid #ebeded;
  background: #fbfcfc;
  outline: none;
  margin-right: 2px;
}

.event_page_url_input_container_right_button {
  cursor: pointer;
  display: flex;
  width: 86px;
  height: 36px;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #939596;
  color: var(--Grays-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.embed_button_container {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 11px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
}

.embed_button_text {
  flex: 1;
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.embed_event_subtext {
  color: #131517;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 118.75% */
}

.preview_button_container {
  border-radius: 10px;
  border: 0.5px solid #e3e4e5;
  background: #f5f6f7;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px 0px;
}

.preview_button {
  color: var(--Grays-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
  letter-spacing: -0.23px;
  cursor: pointer;
  display: flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: #131517;
}

.preview_info_text {
  overflow: hidden;
  color: #131517;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 118.75% */
  margin-top: 28px;
}

.preview_info_text_span_1 {
  color: #696969;
  font-weight: 400;
}

.preview_info_text_span_2 {
  color: #f31a7c;
  cursor: pointer;
}

.cancel_event_button {
  display: flex;
  padding: 7px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #ed2b32;
  cursor: pointer;
  color: var(--Grays-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
  margin-top: 32px;
}

.embed_event_code_container {
  overflow: auto;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
}

pre {
  margin: 0px;
}

.guests_top_section {
  margin-top: 26px;
}

.event_preview {
  border-radius: 13px;
  background: var(--Backgrounds-Primary, #fff);
  width: 100%;
  padding: 8px 12px;
  box-sizing: border-box;
}

.event_preview_container {
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: #fff;
  padding: 8px 10px;
  display: flex;
  align-items: flex-end;
  height: 270px;
}

.event_copy_container {
  border-radius: 10px;
  background: #b2a8a7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 18px;
  cursor: pointer;
  width: 100%;
}

.event_copy_link {
  color: #f0eeee;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.event_share_text {
  overflow: hidden;
  color: #646567;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.4px;
}

.event_share_icon_container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.event_details {
  margin-top: 13px;
}

.event_share_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event_details_container {
  margin-top: 4px;
}

.event_details_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.event_details_container_inner {
  display: flex;
  align-items: center;
  gap: 26px;
  margin-top: 22px;
  margin-left: 8px;
}

.event_details_container_inner_left {
  border-radius: 10px;
  border: 1px solid #ededed;
  background: #fff;
  height: 48px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event_details_container_inner_left_text {
  color: #9e9f9f;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  line-height: 10px;
}

.event_details_container_inner_left_text_primary {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 150% */
}

.event_details_container_inner_right_text_primary {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.event_details_container_inner_right_text {
  color: #67696b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 120% */
}

.checkin_button {
  display: flex;
  padding: 3.5px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f4f4f4;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

.checkin_button_text {
  color: #646567;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.checkin_button_container {
  display: flex;
  gap: 5px;
  margin-top: 6px;
  padding-bottom: 10px;
}

.invitations_container {
  margin-top: 29px;
}

.invitations_container_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invitations_container_heading_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px; /* 133.333% */
}

.invitations_details_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  background: #fdfdfd;
  margin-top: 15px;
  padding: 14px 16px 25px 8px;
  width: 100%;
  box-sizing: border-box;
}

.number_of_guests {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 91.667% */
}

.number_of_guests_subtext {
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.number_of_guests_text {
  overflow: hidden;
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
  margin-top: 8px;
}

.email_opened_text {
  overflow: hidden;
  color: #a9aaaa;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
}

.email_opened_container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.email_opened_icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.recent_guests_container {
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  width: 100%;

  box-sizing: border-box;
  margin-top: 5px;
}

.recent_guests_container_inner {
  padding: 10px 16px 0px;
}

.recent_guests_container_inner_2 {
  padding: 3.5px 16px;
}

.recent_guests_container_text {
  color: #a8a9aa;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 169.231% */
  letter-spacing: -0.43px;
  width: fit-content;
}

.recent_guests_divider {
  background: #e7e8e9;
  height: 1px;
  width: 100%;
  margin: 6px 0px;
}

.recent_guests_container_inner_guest {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 3.5px 0px;
}

.recent_guests_container_inner_guest_icon {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  object-fit: cover;
}

.recent_guests_container_inner_guest_text {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.recent_guests_container_inner_guest_email {
  color: #a8a9aa;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.recent_registrations_container {
  margin-top: 27px;
}

.recent_registrations_container_inner {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.guest_list_filter_item_container_profile_pic {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
  object-fit: cover;
}

.recent_registrations_container_inner_time {
  color: #a9aaaa;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.guest_list_filter_item_container_right_top_status {
  border-radius: 9.5px;
  background: #e5f4e3;
  padding: 3.5px 7.5px;
  height: 19px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.guest_list_filter_item_container_right_top_status_text {
  color: #3cbd2d;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */
}

.guest_list_filter_item_container_right_top_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.host_list_container_text {
  overflow: hidden;
  color: #737577;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
  margin-top: 16px;
}

.host_list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.host_list_item_profile_pic {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  object-fit: cover;
}

.host_list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  padding: 11px 16px;
}

.host_list_item_name {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.host_list_item_edit_container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.system_message_card_divider {
  background: rgba(84, 84, 86, 0.34);
  height: 0.333px;
  width: calc(100% - 16px);
  margin-left: 16px;
  box-sizing: border-box;
}

.image_section {
  display: flex;
  gap: 8px;
  height: fit-content;
}

.image_section_left {
  display: flex;
  flex-direction: column;
  gap: 7.65px;
}

.image_section_img {
  width: calc(71.7vw - 56px);
  border-radius: 12.164px;
  object-fit: cover;
  aspect-ratio: 241/377;
}

.image_section_img_1 {
  border-radius: 12.164px;
  width: 28.3vw;
  aspect-ratio: 118/89;
  object-fit: cover;
}

.title_and_description_title {
  overflow: hidden;
  color: #646567;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* lato heading */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
}

.create_event_secondary_title {
  overflow: hidden;
  color: #646567;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
}

.create_input_box {
  display: flex;
  padding: 9px 16px;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  width: 100%;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  color: #a8a9aa;
  box-sizing: border-box;
  outline: none;
}

.create_input_box:focus {
  border: 1px solid #000;
}

.create_event_input_div {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: #fff;
}

.create_event_input_div_title {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.create_event_input_div_description {
  overflow: hidden;
  color: #67696b;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 120% */
}

.create_event_input_div_text {
  color: #a8a9aa;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.create_event_input_div_2 {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: #fff;
}

.create_event_container_right {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.create_event_capacity_input {
  color: #a8a9aa;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  border: none;
  outline: none;
  background: transparent;
}

.initial_button_primary {
  display: flex;
  height: 51px;
  padding: 13.834px 19.762px;
  justify-content: center;
  align-items: center;
  gap: 8.893px;
  flex-shrink: 0;
  align-self: stretch;

  border-radius: 11.857px;
  background: #fd4f4f;
  color: #fff;

  /* lato sh1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  cursor: pointer;
}

.custom_modal_container {
  padding: 16px 20px;
  width: calc(100vw - 64px);
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.867);
}

.create_event_container_1 {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: calc(48px + 24px);
  transition: all 0.3s ease;
  padding-bottom: 48px;
  max-width: 800px;
  margin: 0px auto;
}

@media (min-width: 600px) {
  .custom_modal_container {
    width: 100%;
  }
  .image_section_img {
    max-width: calc(573.6px - 56px);
    border-radius: 12.164px;
    object-fit: cover;
    aspect-ratio: 241/377;
    width: 241px;
    height: 377px;
  }

  .image_section_img_1 {
    border-radius: 12.164px;
    max-width: 226.4px;
    width: 118px;
    aspect-ratio: 118/89;
    object-fit: cover;
  }
  .image_section_left {
    height: fit-content;
  }
  .create_event_container_1 {
    flex-direction: row;
    padding-bottom: 0px;
    gap: 36px;
  }
  .create_event_container_right {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100vh - 80px);
    overflow-y: auto;
  }
  .sent_blast_card_content {
    flex: 1;
  }
}
