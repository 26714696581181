/* FullScreenModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above other content */
  opacity: 0; /* Start with opacity 0 */
  transition: opacity 0.5s ease-in-out;
}

.modal-overlay.open {
  opacity: 1; /* Modal is fully visible */
}

.modal-content {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #f9f9f9;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transform: translateY(-30px); /* Start from above */
  transition: transform 0.5s ease-out; /* Slide in transition */
}

.modal-overlay.open .modal-content {
  transform: translateY(0); /* Modal slides into place */
}

.modal_icon_container {
  background-color: #efeff0;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clone_event_modal_container {
  padding: 18px;
  max-width: 326px;
}

.modal_title {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.modal_subtitle {
  color: #a8a9a9;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.modal_text_primary {
  overflow: hidden;
  color: #737577;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 135.714% */
}

.modal_secondary_title {
  color: #616265;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.694px; /* 140.671% */
}

.clone_event_modal_container_left {
  display: flex;
  justify-content: space-between;
}

.modal_select_option {
  overflow: hidden;
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}

.clone_event_modal_dropdown_container {
  display: flex;
  gap: 4px;
  margin-top: 8px;
}

.modal_select {
  border-radius: 10px !important;
  border: 1px solid #ebecec !important;
  /* background: #f0f0f0 !important; */
  outline: none !important;
  height: 37px !important;
  box-sizing: border-box !important;
}

.MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: none !important;
}

.clone_event_modal_time_container {
  display: flex;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: #fff;
  padding: 0px 16px;
  margin-top: 3px;
}

.clone_event_modal_time_container_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.clone_event_modal_time_container_line {
  width: 1px;
  background: #edeeee;
}

.modal_button_secondary {
  display: flex;
  padding: 4px 17.904px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 10.742px;
  background: #efeff0;
  cursor: pointer;
  color: #616265;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.694px; /* 140.671% */
  letter-spacing: -0.385px;
}

.clone_event_modal_button_container {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.modal_button_primary {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #333537;
  cursor: pointer;
  color: var(--Grays-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.guest_list_modal_header {
  display: flex;
  gap: 13px;
  align-items: center;
}

.guest_list_modal_header_title {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
}

.guest_list_modal_header_subtitle {
  overflow: hidden;
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.864px; /* 173.864% */
  letter-spacing: -0.093px;
}

.guest_list_modal_header_button {
  border-radius: 12px;
  background: #f6f6f6;
  padding: 3.5px 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: #656668;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.modal_guest_list_search_container {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 7px;
  border: 1px solid #ebeded;
  background: #f4f5f6;
}

.modal_guest_list_search_input {
  overflow: hidden;
  color: #b1b2b2;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
  border: none;
  background: transparent;
  outline: none;
}

.modal_guest_list_search_input::placeholder {
  color: #b1b2b2;
}

.modal_filter_guest_options {
  display: flex;
  gap: 13px;
  align-items: center;
  overflow-x: auto;
  padding: 15px 16px 0px;
}

.modal_filter_guest_options_item {
  padding-bottom: 15px;
}

.modal_filter_guest_options_item_text {
  overflow: hidden;
  color: #68696b;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 112.5% */
  width: max-content;
}

.modal_filter_guest_options_item_text_active {
  color: #000;
}

.modal_filter_guest_options_line {
  background: #e7e8e9;
  height: 1px;
}

.modal_guest_list_item_container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.modal_guest_list_item_name {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 16.227px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.5px; /* 157.143% */
}

.modal_guest_list_item_email {
  overflow: hidden;
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.864px; /* 173.864% */
  letter-spacing: -0.093px;
}

.modal_guest_list_item_status_container {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 9.5px;
  background: #e5f4e3;
  justify-content: center;
  padding: 5px 7.5px;
}

.modal_guest_list_item_status {
  color: #3cbd2d;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px; /* 183.333% */
}

.modal_guest_list_item_checkin_time {
  color: #3cbd2d;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */
}

.guest_popup_body_container {
  padding: 16px 20px;
  background: #f8f8f8;
}

.guest_popup_body_title {
  overflow: hidden;
  color: #b8b8ba;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 135.714% */
}

.guest_popup_body_subtitle {
  color: #a8a9a9;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.guest_popup_checkin_container {
  background: #f0f0f0;
  border-radius: 10px;
  border: 1px solid #def4d6;
  margin: 5px 0px;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
  box-sizing: border-box;
}

.guest_popup_checkin_title {
  overflow: hidden;
  color: #56c248;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 118.75% */
}

.guest_popup_footer_container {
  display: flex;
  gap: 14px;
  align-items: center;
  padding: 12px 16px 16px;
}

.guest_popup_footer_cancel_button {
  padding: 8px 16px;
  border-radius: 12px;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: 1;
  color: #717274;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.guest_popup_footer_checkin_button {
  padding: 8px 16px;
  border-radius: 12px;
  justify-content: center;
  background: #3cbd2d;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  color: var(--Grays-White, #fff);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.custom_question_list_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.custom_question_list_container_item {
  display: flex;
  border-radius: 8px;
  gap: 10px;
  padding: 8px 12px;
  background-color: rgba(19, 21, 23, 0.04);
  align-items: center;
  cursor: pointer;
}

.custom_question_list_container_item_text {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.385px;
  color: rgba(19, 21, 23, 0.64);
  font-family: Lato;
}

.question_header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom_question_top_body_title {
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 133.333% */
  color: #737577;
}

.custom_question_top_body_text {
  color: rgba(19, 21, 23, 0.64);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px; /* 133.333% */
  color: #737577;
}

.custom_question_input {
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: #fff;
  padding: 12px 16px;
  width: 100%;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.43px;
  color: #000;
  background: #fff;
}

.custom_question_input_error {
  border: 1px solid red;
}

.custom_question_input:focus {
  border: 1px solid #000;
}

.custom_question_options_container {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px;
  display: flex;
}

.custom_question_option_container {
  display: flex;
  gap: 4px;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 8px;
}

.custom_question_option_container_active {
  background-color: #fff;
}

.custom_question_option_title {
  color: rgb(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.093px;
  font-family: Lato;
}

.custom_question_option_title_active {
  color: #000;
}

.custom_question_option_icon_active {
  fill: #000;
}

.custom_question_option_icon_active path {
  fill: #000;
}

.custom_question_required_block {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.custom_question_required_block_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
}

.modal_select_white_bg {
  background: #fff !important;
}

.modal_select_white_font {
  font-size: 16px !important;
}

.registration_email_modal_header {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #e7e8e9;
  padding: 12px;
  background-color: #fff;
}

.registration_email_modal_input_container {
  padding: 1rem 1.25rem;
  border-radius: 8px;
  box-shadow: 0 0.7px 2.7px rgba(0, 0, 0, 0.02),
    0 1.7px 6.9px rgba(0, 0, 0, 0.03), 0 3.5px 14.2px rgba(0, 0, 0, 0.04),
    0 7.3px 29.2px rgba(0, 0, 0, 0.05), 0 20px 80px rgba(0, 0, 0, 0.06);
  background-color: #fff;
}

.event_capacity_modal_button_container {
  display: flex;
  gap: 4px;
  margin-top: 16px;
}

.modal_button_secondary {
  display: flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #efeff0;

  cursor: pointer;
  color: rgba(19, 21, 23, 0.64);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
}

.modal_text_secondary {
  color: rgba(19, 21, 23, 0.36);
  font-size: 14px;
  font-weight: 600;
  line-height: 20.864px;
  letter-spacing: -0.093px;
  font-family: Lato;
  cursor: pointer;
  margin-top: 8px;
}

.ticket_restriction_text_container {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  margin-top: 24px;
}

.custom_question_input_helper_text {
  color: red;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.093px;
  font-family: Lato;
  margin-top: 4px;
}

.otp_input {
  width: calc((100% - 60px) / 6);
  aspect-ratio: 1/1;
  max-width: 48px;
  max-height: 48px;
  text-align: center;
  border: 1px solid rgba(19, 21, 23, 0.32);
  border-radius: 8px;
  font-size: 16px;
  outline: none;
}
.otp_input:focus {
  border: 1px solid #000;
}

.host_modal_access_control_container {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid rgba(19, 21, 23, 0.36);
  padding: 8px 12px;
  justify-content: space-between;
}

.host_modal_access_control_container_active {
  border: 1px solid #000;
}

/* .custom_question_option_container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
} */

/* Remove any existing background styles from these classes */
.custom_question_option_container_active {
  background: transparent;
}
