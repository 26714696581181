@media (max-width: 600px) {
  .city-top-section-mobile-wrapper {
    padding: 14px 12px 0px;
  }
  .city-top-section-mobile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
  }
  .city-top-section-mobile-heading {
    color: #000;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .city-top-section-mobile-subheading {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .city-top-section-mobile-text {
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .city-top-section-mobile-text-wrapper {
    margin-top: 11px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .explore-city-subscribe-wrapper {
    display: flex;
    padding: 12px 24px;
    align-items: center;
    gap: 24px;
    flex-direction: row;
    justify-content: space-between;
  }
  .explore-city-subscribe-heading {
    font-size: 24px;
  }
  .explore-city-subscribe-button {
    padding: 8px 16px;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 21px */
    letter-spacing: -0.154px;
  }
  .map-section-wrapper {
    padding: 12px;
    height: 260px;
    border-radius: 30px;
    align-items: flex-end;
  }
  .city-subscribe-description-mobile {
    color: #333;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-top: 16px;
  }
  .experience-section-cards-wrapper {
    gap: 24px;
  }
  .experience-card-month {
    font-size: 14px;
  }
  .experience-card-date {
    font-size: 32px;
  }
  .experience-card-wrapper {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 20px;
    border: 2.5px solid #efefef;
    background: #fff;
  }
  .experience-card-wrapper {
    gap: 16px;
  }
  .experience-card-left-section {
    gap: 16px;
  }
  .experience-card-month-wrapper {
    width: auto;
  }
  .experience-card-middle-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .experience-card-subtitle {
    font-size: 16px;
  }
  .horizontal-line-painted {
    width: 2px;
  }
  .experience-card-image-section {
    width: 106px;
    height: 124px;
    object-fit: cover;
  }
  .city-creator-image-0 {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 100%;
    object-fit: cover;
  }
  .city-creator-image-1 {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    border-radius: 100%;
    object-fit: cover;
    margin-left: -4px;
    border: 2px solid #fff;
  }
  .experience-card-by {
    font-size: 12px;
  }
  .experience-card-dates {
    font-size: 12px;
  }
}
