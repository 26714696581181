.search_bar_container {
  display: flex;
  padding: 7px 8px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid #ebeded;
  background: #fbfcfc;
  box-sizing: border-box;
  height: 36px;
}

.search_bar_input {
  overflow: hidden;
  color: #b1b2b2;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
  flex: 1;
  border: none;
  outline: none;
  box-sizing: border-box;
  background: transparent;
}

.featured_filter_options_container {
  display: flex;
  gap: 11px;
  overflow-x: auto;
  margin-top: 24px;
}

.featured_filter_options_item {
  display: flex;
  padding: 6px 10px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: var(--gap, 4px);
  flex: 1 0 0;
  align-self: stretch;
  cursor: pointer;
}

.featured_filter_options_item_active {
  border-radius: 7px;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  background: #fff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12),
    0px 3px 1px 0px rgba(0, 0, 0, 0.04);
}

.featured_filter_options_item_title {
  overflow: hidden;
  color: rgb(0, 0, 0, 0.7);
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
}

.featured_filter_options_item_title_active {
  color: #000;
}

.featured_filter_options_item_icon_active path {
  fill: #000;
}

.featured_filter_options_item_icon path {
  fill: rgb(0, 0, 0, 0.7);
}

.featured_experience_container {
  color: #000;

  /* lato bh1 */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  margin-top: 24px;
}

.all_experience_option {
  display: flex;
  padding: 3px 11px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #ebeced;
  cursor: pointer;
  position: relative;
  color: #606164;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.43px;
  transform: scale3d(1, 1, 1);
  transition: all 0.3s ease-in-out;
}

.title {
  color: #000;

  /* lato bh1 */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
}

.all_experience_container {
  margin-top: 36px;
}

.featured_filter_options {
  font-size: 32px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  height: calc(24px * (1 - (var(--scroll-progress) * 0.5)));
}

.all_experience_cards_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
}

.reversing {
  opacity: 0;
}

.all_experience_option_active {
  color: #ebecfe;
  background: #606172;
  transition: all 0.3s ease-in-out;
}

.wishlist_empty_container {
  display: flex;
}

.wishlist_empty_container p {
  color: #606164;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}

.search_results_title {
  color: rgba(0, 0, 0, 0.8);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
}

.search_results_no_results {
  color: #606164;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 24px */
}

@media (min-width: 600px) {
  .all_experience_cards_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}
