.city-top-section {
  display: flex;
  position: relative;
  align-items: flex-end;
  height: 574px;
}

.city-top-section-left {
  width: 704px;
  height: 704px;
  flex-shrink: 0;
  border-radius: 704px;
  background: #fff;
  position: absolute;
  top: 0px;
  left: -108px;
  z-index: 2;
}

.city-top-section-text-wrapper {
  display: flex;
  width: 450px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin-top: 135px;
  margin-left: 190px;
}

.city-top-section-text-subwrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.city-top-section-heading {
  color: #000;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.city-top-section-subheading {
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.city-top-section-text {
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 151.02%; /* 24.163px */
}

.map-section-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  border-radius: 30px;
  padding: 24px;
  box-sizing: border-box;
  z-index: 3;
  position: relative;
}

.explore-city-subscribe-wrapper {
  display: flex;
  width: 329px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 25px;
  background: #fff;
  box-sizing: border-box;
}

.explore-city-subscribe-heading {
  color: #333;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.explore-city-subscribe-subheading {
  color: #333;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.explore-city-subscribe-button {
  display: inline-flex;
  padding: 12px var(--9, 36px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 2px solid #ff6f61;
  background: #ff6f61;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  letter-spacing: -0.264px;
  cursor: pointer;
}

.experience-section-heading {
  color: #333;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.experience-section-cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--9, 36px);
  align-self: stretch;
  margin-top: 24px;
}

.experience-card-wrapper {
  display: flex;
  padding: 24px var(--9, 36px);
  align-items: center;
  gap: var(--9, 36px);
  align-self: stretch;
  border-radius: 20px;
  border: 2.5px solid #efefef;
  background: #fff;
  cursor: pointer;
}

.experience-card-left-section {
  display: inline-flex;
  align-items: center;
  gap: 24px;
}

.experience-card-month-wrapper {
  display: flex;
  width: 53px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--15, 6px);
}

.experience-card-month {
  color: rgba(33, 33, 33, 0.48);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.176px;
}

.experience-card-date {
  color: #333;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.495px;
}

.horizontal-line {
  width: 0.5px;
  align-self: stretch;
  background-color: rgba(33, 33, 33, 0.32);
}

.experience-card-title-wrapper {
  display: flex;
  width: 167px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.experience-card-title {
  color: #333;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.264px;
}

.experience-card-days {
  color: #333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.176px;
  opacity: 0.48;
}

.experience-card-image-section {
  object-fit: cover;
  width: 130.5px;
  height: 130.5px;
  flex-shrink: 0;
  border-radius: 11px;
}

.horizontal-line-painted {
  width: 4px;
  align-self: stretch;
  background-color: #fab5af;
}

.experience-card-middle-section {
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  gap: 8px;
  flex-wrap: wrap;
}

.experience-card-subtitle {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
}

.experience-card-description {
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
}

.experience-card-dates {
  color: #333;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.experience-card-right-section {
  display: flex;
  align-items: center;
  gap: 4px;
}

.experience-card-by {
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}

.experience-card-creator-image-section {
  display: flex;
}

.city-creator-image-0 {
  width: 49.904px;
  height: 49.904px;
  flex-shrink: 0;
  border-radius: 100%;
  object-fit: cover;
}

.city-creator-image-1 {
  width: 49.904px;
  height: 49.904px;
  flex-shrink: 0;
  border-radius: 100%;
  margin-left: -15px;
  object-fit: cover;
  border: 2px solid #fff;
}
