.initial_create_title {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.1px;
}

.initial_create_heading_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.initial_create_itinerary_container {
  display: flex;
  gap: 16px;
}

.intial_create_testimonial_container {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: #fff;
}

.eventv3_itn_card_heading::placeholder {
  color: #fff;
}

.intial_create_testimonial_container_top_title {
  overflow: hidden;
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
}

.intial_create_testimonial_container_top_description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  max-width: 250px;
}

.intial_create_testimonial_container_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rdrMonth {
  width: auto !important;
}

.eventv3_itn_card_bottom_section_title {
  color: #000;

  /* lato bb1 */
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
