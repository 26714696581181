:root {
  --swiping-carousel-height: min(calc(100vh - 176px), 438px);
  --swiping-carousel-width: min(
    calc(var(--swiping-carousel-height) * (358 / 534)),
    358px
  );
}

.carousel-container {
  display: flex;
  position: relative;
  width: 100%;
  height: calc((100vw - 60px) / 358 * 534);
  overflow: hidden;
  outline: none;
}

.carousel-item {
  position: absolute;
  width: calc(100vw - 60px);
  background-color: #f0f0f0;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: scale(0.8);
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  border-radius: 24px;
}

.carousel-item.active {
  z-index: 10;
  transform: translateX(-50%) scale(1);
  opacity: 1;
}

.carousel-item.prev {
  transform: translateX(calc(-50vw / 0.85 + 24px - (100vw - 60px) * 0.85))
    scale(0.85);
  opacity: 0.6;
}

.carousel-item.next {
  transform: translateX(calc(((50vw - 30px) * 0.85) + 14px)) scale(0.85);
  opacity: 0.6;
}

@media (min-width: 600px) {
  .carousel-container {
    height: var(--swiping-carousel-height);
    max-height: 534px;
  }
  .carousel-item {
    width: var(--swiping-carousel-width);
    max-width: 358px;
  }

  .carousel-item.prev {
    transform: translateX(
        calc(
          var(--swiping-carousel-width) * -0.85 - var(--swiping-carousel-width) *
            0.5 / 0.85 - 20px
        )
      )
      scale(0.85);
    opacity: 0.6;
  }

  .carousel-item.next {
    transform: translateX(calc(var(--swiping-carousel-width) * 0.85 / 2 + 24px))
      scale(0.85);
    opacity: 0.6;
  }
}
