.button_primary {
  display: flex;
  height: 51px;
  padding: 13.834px 19.762px;
  justify-content: center;
  align-items: center;
  gap: 8.893px;
  flex-shrink: 0;
  align-self: stretch;

  border-radius: 11.857px;
  background: #fd4f4f;
  color: #fff;

  /* lato sh1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  cursor: pointer;
}

.flex_div {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.text_primary {
  color: #000;
  /* lato b1 */
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}

.text_primary_1 {
  color: #fff;

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.text_secondary {
  color: rgba(255, 255, 255, 0.7);

  /* lato b2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}

.text_large {
  color: #fff;

  /* lato heading */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  margin-top: 8px;
}

.payment_pending_container {
  border-radius: 9.5px;
  background: #f8efe1;
  padding: 8px;
}

.payment_pending_text {
  color: #d69712;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */
}

.payment_failed_container {
  background: rgba(237, 43, 50, 0.1);
  padding: 8px;
  border-radius: 9.5px;
}

.payment_failed_text {
  color: rgba(237, 43, 50, 1);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */
}
