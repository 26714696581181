.hero_section_container {
  padding: 22px 25px 8px;
}

.hero_section_top {
  display: flex;
  position: relative;
}

.hero_section_top_left {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px;
}

.hero_img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.participant_count {
  color: #000;
  /* Funky */
  font-family: Gaegu;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  transform: rotate(180deg);
  writing-mode: vertical-rl;
}

.pipe_icon {
  width: 15px;
  height: 0.882px;
  background: #5d5d5d;
}

.hero_section_top_right {
  position: relative;
}

.tape_icon {
  position: absolute;
  top: 0;
  left: -15px;
  top: 25px;
}

.rounded_corner_icon {
  position: absolute;
  bottom: 14px;
  right: 4px;
}

.hero_section_top_right_text {
  color: #fff;
  text-align: right;
  font-family: Gaegu;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.8px;
  position: absolute;
  bottom: 25px;
  right: 50px;
}

.creator_img_container {
  position: absolute;
  bottom: -56px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.creator_name {
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
}

.location_text {
  color: #000;
  font-family: Lato;
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: 120%;
}

.event_description_container {
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  border-radius: 17.911px;
  background: #fff;
  padding: 16px 23px;
  margin: 60px 15px 0px;
}

.event_description_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event_description_top_text {
  color: #000;
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%; /* 27px */
  letter-spacing: -0.198px;
}

.event_dates {
  color: #5d5d5d;

  /* sub heading */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
}

.event_dates_container {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 9px;
}

.event_description_bottom_text {
  color: #000;

  /* Paragraph Text */
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 13px */
  letter-spacing: 0.26px;
  margin-top: 12px;
}

.event_itinerary_container {
  margin-top: 50px;
}

.event_itinerary_heading {
  color: #000;
  font-family: "Playfair Display";
  font-size: 32px;
  font-style: italic;
  font-weight: 600;
  line-height: 120%; /* 38.4px */
  margin-left: 22px;
}

.itinerary_cards_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #787878;
}

.itinerary_day_text {
  color: #000;

  /* SH1 */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
}

.itinerary_date_text {
  color: #000;

  /* sub heading */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
}

.itn_pipe {
  width: 1px;
  height: 30px;
  background: #787878;
}

.itinerary_cards_container {
  padding: 22px 18px;
}

.itinerary_img {
  width: 100%;
  height: 164px;
  object-fit: cover;
  margin-top: 12px;
}

.itinerary_description_text {
  margin-top: 25px;
  color: #000;

  /* Paragraph Text */
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 13px */
  letter-spacing: 0.26px;
  margin-bottom: 30px;
}

.creator_section_container {
  margin: 56px 25px 80px;
}

.creator_section_top {
  display: flex;
  gap: 35px;
  justify-content: space-between;
}

.creator_section_top_text {
  color: #000;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 143%; /* 20.02px */
}

.creator_img {
  width: 173px;
  height: 196px;
  object-fit: cover;
}

.creator_img_1 {
  width: 126.701px;
  height: 132.933px;
  flex-shrink: 0;
  border-radius: 8.474px;
  box-shadow: 0px 3.98px 3.98px 0px rgba(0, 0, 0, 0.25);
  object-fit: cover;
}

.creator_section_bottom {
  display: flex;
  justify-content: space-between;
}

.creator_section_bottom_text_1 {
  color: #000;
  font-family: Lato;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 13.2px */
  letter-spacing: 0.33px;
  text-transform: uppercase;
}

.creator_section_bottom_text_2 {
  color: #000;
  font-family: "Playfair Display";
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 120%; /* 24px */
}

.creator_section_bottom_right {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-top: 16px;
}

.creator_section_bottom_right_text {
  color: #000;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 15.6px */
  letter-spacing: 0.39px;
  text-transform: uppercase;
}

.testimonial_card {
  padding: 28px 15px 15px;
  border-radius: 4px;
  min-width: 140px;
  min-height: 184px;
  box-sizing: border-box;
}

.testimonial_card:nth-child(even) {
  margin-top: 12px;
}

.testimonial_card:nth-child(1) {
  background: #f8efe5;
  border: 0.643px solid #f8efe5;
  filter: drop-shadow(1.928px 4.498px 10.153px rgba(0, 0, 0, 0.25));
}

.testimonial_card:nth-child(2) {
  background: #ffb2a5;
  margin-left: calc(50vw - 225px);
  border: 0.643px solid #ffb2a5;
  filter: drop-shadow(1.928px 4.498px 10.153px rgba(0, 0, 0, 0.25));
}

.testimonial_card:nth-child(3) {
  margin-left: calc(50vw - 225px);
  padding: 23px 15px 15px;
  background: #fff;
  border: 0.643px solid #fbfbfb;
  filter: drop-shadow(1.928px 4.498px 10.153px rgba(0, 0, 0, 0.25));
}

.testimonials_container {
  display: flex;
  align-items: start;
  margin: 0px 15px 50px;
}

.testimonial_description {
  color: #2e2e2e;
  -webkit-text-stroke-width: 0.6425924897193909;
  -webkit-text-stroke-color: #f5f5f5;
  font-family: Lato;
  font-size: 11.567px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 13.88px */
  margin-top: 23px;
}

.testimonial_name {
  color: #808080;
  font-family: Lato;
  font-size: 6.426px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 7.711px */
  margin-top: 12px;
}

.footer_container {
  background: #fff;
  box-shadow: 0px 1.99px 11.94px 0px rgba(0, 0, 0, 0.25);
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  z-index: 100;
}

.footer_text {
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.176px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.footer_button {
  height: 37.361px;
  flex-shrink: 0;
  border-radius: 9px;
  background: #ff6f61;
  color: #fff;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
}

@media (min-width: 800px) {
  .testimonial_card:nth-child(even) {
    margin-left: 0px;
  }

  .testimonial_card:nth-child(odd) {
    margin-left: 0px;
  }
}
