.welcome_text {
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.welcome_sub_text {
  color: #878787;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 14px;
}

.input_label {
  color: #3c3c3c;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.17px;
}

.input_box {
  width: 100%;
  height: 54px;
  flex-shrink: 0;
  border-radius: 14px;
  border: 2px solid #dedede;
  background: #fff;
  box-shadow: 0px 0px 1.8px 0px rgba(0, 0, 0, 0.25);
  outline: none;
  color: var(--Primary-Black, #212121);

  /* Inter/Reg/Para2 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 18px 14px;
  box-sizing: border-box;
}

.action_button {
  border-radius: 14px;
  border: 2px solid #dedede;
  background: #ff6f61;
  box-shadow: 0px 0px 1.8px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 54px;
  flex-shrink: 0;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-box-error {
  border-radius: 14px;
  border: 2px solid #e72d2d;
  background: #fff;
  box-shadow: 0px 0px 1.8px 0px rgba(0, 0, 0, 0.25);
}

.input_helper_text {
  /* Inter/Bold/Description */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.input_helper_text_error {
  color: #d00;
}

.back_button_signup {
  color: #000;

  /* Inter/Bold/Para1 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.forgot_password_text {
  color: #878787;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
}

.otp_verification_text {
  border-radius: 20px;
  background: #fbf7f3;
  padding: 15px 24px;
  box-sizing: border-box;
  width: 100%;
  color: #000;

  /* Inter/Reg/Para1 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.didnt_receive_text {
  color: var(--Primary-Black, #212121);

  /* Inter/Reg/Para2 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.48;
}

.wait_text {
  color: #565555;

  /* Inter/Bold/Para2 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.or_text {
  color: #757575;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.insta-login-container {
  display: flex;
  width: 280px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 9999px;
  border: 2px solid #e2e8f0;
  background: #fff;
  cursor: pointer;
}

.insta-login-text {
  color: #1e293b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.24px;
}

.login-icon-wrapper {
  display: inline-flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 9999px;
  border: 2px solid #e2e8f0;
  background: #fff;
  cursor: pointer;
}
