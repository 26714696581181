.discover-text {
  color: #f25d07;
  font-family: Poppins;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 56px;
}

.discover-text-span {
  color: #000;
}

.discover-subtext {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 22px;
}

.discover-section-header {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.creator-container {
  display: flex;
  gap: 24px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 26px;
  height: fit-content;
  padding-top: 10px;
  padding-bottom: 7px;
}

.creator-img {
  width: 173px;
  height: 199px;
  aspect-ratio: 173/199;
  border-radius: 16px;
  opacity: 0.94;
  object-fit: cover;
  border: 1px solid white;
}

.browse-container {
  padding-top: 31px;
  display: flex;
  gap: 36px;
  overflow-x: auto;
}

.browse-card-div {
  border-radius: 16px;
  box-sizing: border-box;
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--Corner-Medium, 12px);
  border: 2.5px solid #efefef;
  min-width: 126px;
  box-sizing: border-box;
}

.browse-card-text {
  color: #2a2d34;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin-top: 22px;
}

.popular-card-container {
  margin-top: 34px;
  display: flex;
  gap: 42px;
  overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 7px;
}

.popular-card-div {
  min-width: 331px;
  border-radius: 16px;
  border: 1px solid #d5d1d1;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.popular-card-img {
  width: 100%;
  aspect-ratio: 323/186;
  border-radius: 16px;
  padding: 4px;
  box-sizing: border-box;
  object-fit: cover;
}

.popular-card-bottom {
  padding: 12px;
}

.popular-bottom-section-header {
  color: var(--Primary-Black, #212121);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.8px;
}

.popular-bottom-section-subdiv {
  display: flex;
  align-items: center;
  gap: 4px;
}

.popular-bottom-section-by {
  color: #2a2d34;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}

.popular-bottom-section-location {
  color: #404040;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: -0.56px;
}

.popular-creator-img {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  object-fit: cover;
}

.popular-creator-div {
  display: flex;
}

.location-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 42px 21px;
  margin-top: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-x: auto;
}

.location-card-container {
  border-radius: 16px;
  border: 1px solid #d5d1d1;
  padding: 22px 44px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.location-text {
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
}

.location-text-sub {
  color: #888;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 18px */
}

@media (hover: hover) and (pointer: fine) {
  /* .creator-img:hover,
  .popular-card-div:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  } */
  .browse-card-div:hover {
    /* border: 2px solid #404040; */
    cursor: pointer;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  }
  .creator-img:hover,
  .popular-card-div:hover,
  .location-card-container:hover {
    /* border: 1px solid #000; */
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, border 0.3s ease;
    cursor: pointer;
  }
}
