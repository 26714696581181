.navbar-container {
  padding: 8px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 4.1px -4px rgba(0, 0, 0, 0.25);
}

.logo {
  color: #444446;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
}

.create-button {
  min-width: 172px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #ff6f61;
  display: inline-flex;
  padding: 12px var(--12, 48px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.book-button {
  display: flex;
  padding: 1px 4.546px 1px 21px;
  align-items: center;
  gap: 7px;
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 54px;
  background: #fe423f;
  height: 52px;
}

.create-button-text {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 36px */
  letter-spacing: -0.264px;
}

@media screen and (max-width: 600px) {
  .logo {
    font-size: 20px;
  }
  .create-button {
    display: inline-flex;
    padding: 7px 20px 7px 19px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background: #ff6f61;
    box-shadow: 0px 0px 1.006px 0px rgba(23, 26, 31, 0.12),
      0px 0px 0.503px 0px rgba(23, 26, 31, 0.07);
    width: auto;
    height: auto;
  }
  .book-button {
    display: inline-flex;
    height: 32px;
    padding: 3px 7.695px 4px 15px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 14px;
    background: #fe423f;
    width: auto;
  }
  .create-button-text {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.154px;
  }
}
