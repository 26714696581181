@media (max-width: 600px) {
  .discover-text {
    font-size: 24px;
    margin-top: 48px;
  }
  .discover-subtext {
    margin-top: 21px;
    font-size: 20px;
  }
  .creator-container {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .location-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 21px 10px;
  }
}
