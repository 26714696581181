.modal_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 17px;
  border: 1px solid #efefef;
  background: #fefefe;
}

.modal_nav_heading {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  flex: 1;
}

.edit_blast_modal_container {
  padding: 0px 20px;
  margin-top: 12px;
}

.particiapant_detail_list_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.particiapant_detail_list_container_item {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 11.591px;
  padding: 7px 0px;
}

.particiapant_name {
  color: var(--Labels-Primary, #000);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16.227px;
  font-style: normal;
  font-weight: 700;
  line-height: 25.5px; /* 157.143% */
}

.particiapant_time {
  overflow: hidden;
  color: #a9aaaa;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.864px; /* 173.864% */
  letter-spacing: -0.093px;
  margin-top: -4.64px;
}

.particiapant_detail_list_container_item_text {
  flex: 1;
}

.particiapant_icon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  object-fit: cover;
}

.participant_edit_button {
  display: flex;
  padding: 3.5px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #eeeff0;
  color: #626366;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.43px;
  cursor: pointer;
}

.edit_blast_modal_container_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.08px;
  margin-top: 17px;
}

.edit_blast_modal_container_text_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.edit_blast_modal_container_text_secondary {
  color: #a9aaaa;
  text-align: right;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: -0.08px;
}

.manage_reminders_modal_container {
  padding: 0px 20px;
  margin-top: 16px;
}

.modal_heading_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal_heading_container_text {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.toggle-container {
  position: relative;
  width: 37px;
  height: 22.49px;
  border-radius: 72.549px;
  background: lightgrey;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Slider */
.slider {
  position: absolute;
  top: 1px;
  left: 2px;
  width: 19.588px;
  height: 19.588px;
  flex-shrink: 0;
  border-radius: 72.549px;
  background: #fff;
  box-shadow: 0px 0px 0px 0.725px rgba(0, 0, 0, 0.04),
    0px 2.176px 5.804px 0px rgba(0, 0, 0, 0.15),
    0px 2.176px 0.725px 0px rgba(0, 0, 0, 0.06);
  transition: all 0.3s;
}

/* Active State */
.toggle-container.active {
  background-color: #000;
}

.toggle-container.active .slider {
  transform: translateX(13.5px);
}

.reminder_subtext {
  color: #737577;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-top: 6px;
  line-height: 19px; /* 135.714% */
}

.reminder_list_container {
  border-radius: 10px;
  border: 1px solid #ebecec;
  background: var(--backgrounds-grouped-secondary, #fff);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.reminder_list_container_item {
  padding: 8px 16px 12px;
}

.reminder_list_container_item_top {
  display: flex;
  align-items: center;
  gap: 4px;
}

.reminder_list_container_item_title {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.reminder_list_container_item_bottom {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reminder_list_container_item_to {
  color: #76787a;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: -0.08px;
}

.reminder_list_container_item_time {
  overflow: hidden;
  color: #3cbd2d;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: -0.08px;
}
